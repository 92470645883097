
const SonyHeader = () => {
return (
    <div style={sonyHeaderStyle}>
        <div style={sonyHeaderLogo}>
            <a href="https://www.sony.net/">
                <img src={`${process.env.PUBLIC_URL}/sony_logo.svg`} alt="SONY" style={{opacity: 1, height: 17, margin: 0, padding: 0, fill: "white"}}  />
            </a>
        </div>
    </div>
)};

const sonyHeaderStyle= {
    display:"flex",
    justifyContent:"center",
    flexDirection: "column",
    alignItems: "center",
    background: "#000",
    height: 64,
    margin: 0,
    padding: 0,
}

const sonyHeaderLogo = {
    width: "60%",
    paddingLeft: "30px",
    align: "left",
    color: "white",
}

export default SonyHeader;